import swal from 'sweetalert';
import {
  accountInfoFields,
  accountOrgInfoFields,
  addContentDiv,
  addCopyButton,
  addLoadAccountDetailsButton,
  addLoadOrderDetailsButton,
  addToast,
  checkValueAgainstList,
  configureTableColumns,
  createAndAddInfoTable,
  createAndAddInfoTableWide,
  displayErrorPopup,
  displayInfoPopup,
  displaySuccessPopup,
  encodeEntities,
  retrieveVisibleFields,
  rowDetailsClickHandler,
  showErrorFromApiOperation,
} from './main';
import { addButtonSpinner, addSpinner, addTableSpinner, removeSpinners } from './sidebar';
import { apiAxios, baseApiAxios, loadSettings, showError } from './api';
import { COGNITO } from './auth';
import { checkResponsibility } from './dashboard';
import { setSearchParamsUrl } from './search_params';
import { CONF } from './environment';
import AccountTabs from '../../jsx/components/forms/AccountTabs';
import { initDataTable } from './datatable';
import EditAccountsForm from '../../jsx/components/forms/EditAccountsForm';

export const accountIdPattern = /^\d{12}$/;

export function showAccountTab(n) {
  const x = document.getElementsByClassName('tab');
  for (let tab of x) {
    tab.style.display = 'none';
  }
  x[n].style.display = 'block';
}

export function accountResetSpinners(submitButton = '#submitButton') {
  $(submitButton).removeClass('loading-animation');
  $(submitButton).attr('disabled', false);
  removeSpinners();
}

export function accountResetForm(form, submitButton = '#submitButton') {
  $(form)[0].reset();
  if ($(submitButton)[0]) {
    $(submitButton)[0].value = 'Continue';
    $(submitButton)[0].setAttribute('class', 'btn btn-success');
  }
  $(submitButton).removeClass('loading-animation');
  $(submitButton).attr('disabled', false);
  $('#account-selector').removeClass('has-error');
  $('.selectpicker').each(() => {
    const selectpicker = $(this);
    const isDisabled = selectpicker.hasClass('disabled');
    selectpicker.selectpicker('refresh');
    selectpicker.not('.no-deselect').selectpicker('deselectAll');
    if (isDisabled) selectpicker.attr('disabled', true);
  });
  $('#form-error').hide();
  $('#form-deletion-warning').hide();
  showAccountTab(0);
}

export async function checkAccountResources() {
  const account_id = $('#aws-account-id')[0].value.split(';')[0];
  const [vpcs, hosted_zones, fourwheels] = await Promise.all([
    baseApiAxios.get('/accounts/' + account_id + '/vpcs', null, { only_active: true, fields: 'vpc_id' }),
    baseApiAxios.get('/accounts/' + account_id + '/dns', null, { only_active: true, fields: 'fqdn' }),
    baseApiAxios.get('/accounts/' + account_id + '/fourwheels', null, {
      exclude_deleted: true,
      fields: 'project_name',
    }),
  ]).catch(showErrorFromApiOperation('Error retrieving remaining resources'));

  return vpcs.items
    .map(function (item) {
      return {
        Type: 'VPC',
        ID: item.vpc_id,
      };
    })
    .concat(
      hosted_zones.items.map(function (item) {
        return {
          Type: 'Hosted Zone',
          ID: item.fqdn,
        };
      }),
      fourwheels.items.map(function (item) {
        return {
          Type: '4wheels',
          ID: item.project_name,
        };
      })
    );
}

export function closeAccount(callbackFct) {
  const account_id = $('#aws-account-id')[0].value.split(';')[0];

  swal({
    title: 'Are you sure?',
    text:
      'Are you sure you want to close AWS account ' +
      account_id +
      '? Once the account is closed, BMW will lose access to all resources and data!',
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      reset: {
        text: 'Close Account',
        value: true,
        className: 'swal-button swal-button--confirm swal-button--danger',
      },
    },
    dangerMode: true,
  }).then(response => {
    if (response) {
      const payload = {
        action: 'account-close',
        description: 'Close AWS Account ' + account_id,
        account_id: account_id,
      };

      baseApiAxios
        .createOrder(payload)
        .then(result => {
          if (callbackFct) callbackFct();
          displaySuccessPopup(result.message);
        })
        .catch(displayErrorPopup)
        .finally(() => {
          accountResetForm('#close-account-form');
          removeSpinners();
        });
    } else {
      accountResetSpinners();
    }
  });
}

export function requestIcpException(callbackFct) {
  const account_id = $('#aws-account-id')[0].value.split(';')[0];

  swal({
    title: 'Are you sure?',
    text: 'Are you sure you want to request a ICP exception for the account ' + account_id + '?',
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      reset: {
        text: 'Request ICP Exception',
        value: true,
        className: 'swal-button swal-button--confirm swal-button--danger',
      },
    },
    dangerMode: true,
  }).then(response => {
    if (response) {
      const payload = {
        action: 'account-icp-exception',
        description: 'Request ICP exception for the account ' + account_id,
        account_id: account_id,
      };

      baseApiAxios
        .createOrder(payload)
        .then(result => {
          if (callbackFct) callbackFct();
          displaySuccessPopup(result.message);
        })
        .catch(displayErrorPopup)
        .finally(() => {
          accountResetForm('#icp-exception-account-form');
          removeSpinners();
        });
    } else {
      accountResetSpinners();
    }
  });
}

export async function accountGetDetails(form) {
  const account_id = document.getElementById('aws-account-id').value;
  try {
    const account_details = await baseApiAxios.getAccount(account_id);
    accountDisplayDetails(account_details, form);
  } catch (err) {
    showErrorFromApiOperation('Failed to fetch account details')(err);
  }
}

function accountDisplayDetails(account_data, form) {
  const content = document.getElementById('account-details');
  content.setAttribute('class', 'detailsContainer');
  content.innerHTML = '';

  const infoContainer = document.createElement('div');
  content.appendChild(infoContainer);
  formatChildRowListAccountsHeader(account_data, infoContainer);

  const detailContainer = document.createElement('div');
  content.appendChild(detailContainer);
  formatChildRowListAccountsLeftSide(account_data, detailContainer);

  const permissions = localStorage.permissions || false;
  const account_areas = localStorage.account_areas || false;
  const responsible = checkResponsibility(account_data, COGNITO.user.email);
  const area_admin = checkValueAgainstList(account_data['account_area'], account_areas);

  if (
    checkValueAgainstList('manage_organizations', permissions) ||
    checkValueAgainstList('manage_accounts', permissions) ||
    responsible ||
    area_admin ||
    form === 'icp-exception-account-form'
  ) {
    $('#submitButton').attr('disabled', false);
  } else {
    $('#submitButton').attr('disabled', true);
  }

  if (account_data.marked_for_deletion) {
    $('#submitButton').attr('disabled', true);
  }
  removeSpinners();
}

export function accountDisplayRemainingResources(response) {
  const content = document.getElementById('account-details');
  content.setAttribute('class', 'detailsContainer');
  content.innerHTML = '';

  const infoContainer = document.createElement('div');
  infoContainer.setAttribute('class', 'detailsContent');
  content.appendChild(infoContainer);

  const header = document.createElement('h4');
  header.innerText = 'Remaining Resources';
  infoContainer.appendChild(header);

  const headerText = document.createElement('p');
  headerText.innerText =
    'The selected account account can not be deleted. In order to close the account please delete the following remaining resources:';
  infoContainer.appendChild(headerText);

  const resourcesContainer = addContentDiv(content, 'detailsContent');
  const resourcesDetails = addContentDiv(resourcesContainer, 'div');

  const resourcesFields = ['Type', 'ID'];
  createAndAddInfoTableWide(response, resourcesFields, resourcesDetails, true);
}

export function updateAccountSummary(data) {
  const accounts_active = document.getElementById('account-active-value');
  const accounts_advanced = document.getElementById('account-advanced-value');
  const accounts_default = document.getElementById('account-default-value');
  const accounts_other = document.getElementById('account-other-value');
  const container_other = document.getElementById('account-other-container');

  const number_accounts = { advanced: 0, default: 0, other: 0 };
  data.forEach(function (item) {
    if (Object.prototype.hasOwnProperty.call(number_accounts, item.account_type)) {
      number_accounts[item.account_type] += 1;
    } else {
      number_accounts.other += 1;
    }
  });

  accounts_active.innerText = data.length;
  accounts_advanced.innerText = number_accounts.advanced;
  accounts_default.innerText = number_accounts.default;
  accounts_other.innerText = number_accounts.other;
  if (number_accounts.advanced + number_accounts.default !== data.length) {
    container_other.classList.remove('d-none');
  } else {
    container_other.classList.add('d-none');
  }
}

function triggerAllAccountsOrgaDetailsUpdate() {
  addSpinner();
  baseApiAxios
    .post('/organizations/trigger-update')
    .then(triggerAccountSuccess)
    .catch(showError)
    .finally(removeSpinners);
}

function triggerAllAccountsAccDetailsUpdate() {
  addSpinner();
  baseApiAxios.post('/accounts/trigger-update').then(triggerAccountSuccess).catch(showError).finally(removeSpinners);
}

function parseText(encodedStr) {
  const parser = new DOMParser();
  const dom = parser.parseFromString(encodedStr, 'text/html');
  return dom.body.textContent;
}

function triggerAccountSuccess(response) {
  displaySuccessPopup(response.message, 'Your request was successful.');
}

function intVal(i) {
  // Remove the formatting to get integer data for summation
  return typeof i === 'string' ? i.replace(/[$,]|[CN¥,]/g, '') * 1 : typeof i === 'number' ? i : 0;
}

function reduceColumnValues(a, b) {
  a = a == '-' ? 0 : a;
  b = b == '-' ? 0 : b;
  return intVal(a) + intVal(b);
}

// update the whole page, e.g. after some database change was made
function updateAccountPage(accountId, d) {
  removeSpinners();
  switch (d.status) {
    case 200:
    case 201:
      hideAccountEditForm();
      if (window.location.hash === '#accountdetails') {
        window.location.search = window.sessionStorage.lastSearch;
        loadAccountData('', '', '', accountId);
        return;
      }
      break;
    case 400:
    case 401:
    case 404:
      $('#form-error')
        .html('<strong>Error: </strong>' + d.data.message)
        .show();
      break;
    case 412:
      $('#form-error').html(
        '<strong>Warning: </strong>' +
          d.data.message +
          '. you have entered an unsupported value or you are not allowed to update the following fields: ' +
          d.data.warning
      );
      $('#form-error').show();
      break;
  }
}

export function loadAccountData(_eventTypeId, _eventCategory, _option, account_id) {
  hideAccountEditForm();
  if (!account_id) return;
  addSpinner();

  // Only add content if both API calls are successful, catch any errors from those API calls and remove the spinners
  // at the end.
  Promise.all([baseApiAxios.getAccount(account_id), baseApiAxios.getAccountPermissions(account_id)])
    .then(response => {
      const account_details = { ...response[0], ...response[1].permanent_roles };
      const temp_access_roles = response[1].temporary_roles || [];

      $('#account-details-main').html(
        <AccountTabs data={account_details} tempAccessRoles={temp_access_roles} isAccountDetailsPage={true} />
      );
    })
    .catch(err => {
      showErrorFromApiOperation('Error fetching account information')(err);
    })
    .finally(() => {
      removeSpinners();
    });
}

// edit accounts

const editFields = [
  'region_category',
  'master_account',
  'account_id',
  'account_root_email',
  'creation_date',
  'fpc_status',
  'account_friendly_name',
  'description',
  'account_area',
  'account_type',
  'account_stage',
  'primary_responsible',
  'sec_responsible',
  'it_responsible',
  'app_id',
  'appd_id',
  'cost_center',
  'psp',
];

let editAccountInitialData = {};
let editAccountSettings = {};
let privateVpcFound;
let publicVpcFound;

// hide the edit account information form
export function hideAccountEditForm() {
  $('#edit-account-form').hide();
  $('.edit-account-show').hide();
  $('.edit-account-hide').show();
  const form = document.getElementById('create-or-edit-form');
  form?.reset();
  $('.selectpicker').selectpicker('refresh');
  $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
  remove_form_warnings();
}

export function preEditAccount(row) {
  let accountData;
  if (typeof row === 'number') {
    const dt = $('#table-accounts').DataTable({ retrieve: true });
    accountData = dt.row(row).data();
  } else if (typeof row === 'string') {
    accountData = JSON.parse(row);
  } else {
    accountData = row;
  }

  // Add the edit form only after someone clicked the edit button and not before
  $('#edit-accounts-form-container').html(
    <EditAccountsForm
      regionCategoryFct={updateRegionCategory}
      masterAccountIdFct={updateMasterAccountId}
      fpcStatusFct={updateFpcStatus}
      accountTypesFct={() => updateAccountTypes({}, accountData, true)}
      accountStagesFct={() => updateAccountStages(accountData)}
      hideFormFct={hideAccountEditForm}
      accountId={accountData.account_id}
      accountData={accountData}
    />
  );
  $('#edit-account-form').show();
  $('.edit-account-show').show();
  $('.edit-account-hide').hide();

  editAccountInitialData = accountData;
  updateFpcStatusDropdown(accountData);
  addSpinner();
  addButtonSpinner();

  apiAxios
    .get(`/accounts/${accountData['account_id']}/vpcs?only_active=true&fields=vpc_id,network_type`)
    .then(apiResponse => {
      if (apiResponse)
        privateVpcFound = apiResponse.data.items.some(item => item && item['network_type'] === 'private');
      publicVpcFound = apiResponse.data.items.some(item => item && item['network_type'] === 'public');
    });

  loadSettings(['update_account_information']).then(settings => {
    editAccountSettings = settings;
    editAccount(settings, accountData);
  });
}

// fill the edit account form with current account information
function editAccount(settings, account_data) {
  const permissions = localStorage.permissions;
  const account_areas = localStorage.account_areas ? localStorage.account_areas : false;
  const manage_organizations = checkValueAgainstList('manage_organizations', permissions);
  const manage_accounts = checkValueAgainstList('manage_accounts', permissions);
  const responsible = checkResponsibility(account_data, COGNITO.user.email);
  const area_admin = checkValueAgainstList(account_data['account_area'], account_areas);
  const { update_account_information: update_settings } = settings;

  editFields.forEach(function (item) {
    $('#' + item).attr('disabled', true);
    const field = document.querySelector('#' + item);
    if (field) {
      switch (item) {
        case 'description':
          field.value = parseText(account_data[item] || '');
          break;
        case 'legacy':
          field.value = account_data[item] || 'legacy';
          break;
        case 'cost_center':
        case 'psp':
        case 'app_id':
        case 'appd_id':
          if (
            account_data &&
            account_data['cost_data'] &&
            account_data['cost_data'][item] &&
            account_data['cost_data'][item] !== 'false' &&
            account_data['cost_data'][item] !== 'unknown'
          ) {
            field.value = account_data['cost_data'][item];
          } else {
            field.value = '';
          }
          break;
        default:
          field.value = account_data[item] || '';
          break;
      }
    }
  });

  updateAccountAreas(settings, account_data);

  $('#card-heading-text')[0].innerText = 'Update AWS Account Record in DynamoDB';

  let updateEnabled = true;

  const enabledFields = update_settings.attributes?.enabled || [];

  if (manage_organizations || manage_accounts || responsible || area_admin) {
    enabledFields.forEach(function (item) {
      const element = $('#' + item);
      element.attr('disabled', false);

      // Text fields with edit buttons next to it have to be handled differently
      if (element.hasClass('button-control')) {
        const elementEdit = $('#' + item + '_edit');
        elementEdit.attr('disabled', false);
      }
    });
  } else {
    updateEnabled = false;
  }

  const updateAccountBtn = document.querySelector('button.update-account');
  if (updateAccountBtn) updateAccountBtn.disabled = !updateEnabled;

  updateFpcStatus();
  removeSpinners();
  setSearchParamsUrl({ account_id: account_data.account_id });
}

function updateFpcStatusDropdown(account_data) {
  const sel = document.getElementById('fpc_status');

  if (!sel) return;

  if (sel.options) {
    for (let i = sel.options.length - 1; i >= 0; i--) {
      sel.remove(i);
    }
  }

  const fpc_stats = {
    new: 'New',
    enabled: 'Enabled',
    external: 'External',
  };

  const current_fpc_status = account_data['fpc_status'];
  if (!fpc_stats[current_fpc_status]) {
    fpc_stats[current_fpc_status] = current_fpc_status;
  }

  for (let stat in fpc_stats) {
    const opt = document.createElement('option');
    opt.value = stat;
    opt.innerText = fpc_stats[stat];

    if (account_data) {
      if (stat === account_data.fpc_status) {
        opt.selected = true;
      }
    }
    sel.appendChild(opt);
  }
}

export function updateFpcStatus() {
  const fpc_status = $('#fpc_status')[0].value;

  switch (fpc_status) {
    case 'deleted':
      ['fpc_status'].forEach(function (item) {
        $('#' + item).attr('disabled', true);
      });
      break;
    case 'halt':
      editFields.forEach(function (item) {
        if (['account_root_email', 'fpc_status'].indexOf(item) === -1) $('#' + item).attr('disabled', true);
      });
      break;
    default:
  }
}

export function updateRegionCategory() {
  const region_category = $('#region_category')[0].value;
  if (region_category === 'China') {
    $('#master_account').attr('disabled', true);
    $('#account_root_email').attr('disabled', true);
    $('#master_account')[0].value = '';
  } else {
    $('#master_account').attr('disabled', false);
    $('#account_root_email').attr('disabled', false);
  }

  updateAccountTypes();
}

export function updateMasterAccountId() {
  updateAccountAreas(editAccountSettings);
}

function getAllowedFieldValues(conditionsArray, accountData, masterAccount, accountArea) {
  const userPermissions = localStorage.permissions?.split(',');

  let allowedValues = [];

  conditionsArray.forEach(conditionObj => {
    const allConditions = Object.assign(conditionObj.conditions?.account || {}, conditionObj.conditions?.network || {});
    const conditionKeys = Object.keys(allConditions);
    const conditionsMet =
      conditionKeys.length > 0
        ? conditionKeys.every(key => {
            switch (key) {
              case 'master_account':
                return allConditions[key].includes(masterAccount || accountData[key]);
              case 'account_area':
                return allConditions[key].includes(accountArea || accountData[key]);
              case 'network_type':
                if (allConditions[key].includes('public') && publicVpcFound) {
                  return false;
                } else if (allConditions[key].includes('private') && privateVpcFound) {
                  return false;
                } else {
                  return true;
                }
              default:
                return allConditions[key].includes(accountData[key]);
            }
          })
        : true;
    const permissionsAvailable =
      conditionObj.permissions?.length > 0
        ? conditionObj.permissions.every(permission => userPermissions.includes(permission))
        : true;
    if (conditionsMet && permissionsAvailable) allowedValues = allowedValues.concat(conditionObj.values);
  });

  return allowedValues;
}

function updateAccountAreas(settings, account_data) {
  account_data = account_data || {};

  const masterAccount = $('#master_account').val();
  const accountArea = account_data.account_area;
  const { update_account_information: updateAccountSettings } = settings;
  const conditionsArray = updateAccountSettings.attributes?.conditional?.account_area || [];

  let accountAreaValues = getAllowedFieldValues(conditionsArray, account_data, masterAccount);

  if (accountArea && accountAreaValues.indexOf(accountArea) === -1) {
    accountAreaValues.push(accountArea);
  }
  if (accountAreaValues.length === 0) {
    accountAreaValues.push('unknown');
  }

  $('select#account_area')
    .empty()
    .append(
      accountAreaValues.map(key =>
        $('<option>')
          .val(key)
          .text(key)
          .attr('selected', key === accountArea || (!accountArea && key === 'fpc'))
      )
    );

  updateFpcStatusDropdown(account_data);
  updateAccountTypes(settings, account_data);
}

export function updateAccountTypes(settings, account_data, skip_load_settings) {
  if (settings || skip_load_settings) {
    updateAccountTypesWithSettings(settings, account_data);
  } else {
    loadSettings('update_account_information').then(settingsFromAPI => {
      updateAccountTypesWithSettings(settingsFromAPI, account_data);
    });
  }
}

function updateAccountTypesWithSettings(settings, accountData) {
  removeSpinners();

  const masterAccount = $('#master_account').val();
  const accountArea = $('#account_area').val();

  const { update_account_information: updateAccountSettings } = settings;
  const conditionsArray = updateAccountSettings.attributes?.conditional?.account_type || [];

  let accountTypeValues = getAllowedFieldValues(conditionsArray, accountData, masterAccount, accountArea);

  const accountType = accountData.account_type || 'advanced';
  if (accountTypeValues.indexOf(accountType) === -1) {
    accountTypeValues.push(accountType);
  }
  if (accountType === 'default' && privateVpcFound) {
    const index = accountTypeValues.indexOf('advanced');
    if (index !== -1) accountTypeValues.splice(index, 1);
  }
  if (accountTypeValues.length === 0) {
    accountTypeValues.push('unknown');
  }

  updateAccountTypesDropdown(accountTypeValues, accountType);
  updateAccountStages(accountData);
}

function updateAccountTypesDropdown(types, account_type) {
  const sel = document.getElementById('account_type');
  for (let i = sel.options.length - 1; i >= 0; i--) {
    sel.remove(i);
  }

  types.forEach(type => {
    const opt = document.createElement('option');
    opt.value = type;
    opt.innerText = type;

    if (type === account_type) {
      opt.selected = true;
    }

    sel.appendChild(opt);
  });
}

export function updateAccountStages(account_data) {
  const appid = $('#app_id')[0].value;
  const appid_pattern = /^APP-\d{4,6}$/;
  const appid_result = appid_pattern.test(appid);

  let { account_stage: accountStage } = account_data || {};
  if (!accountStage) {
    accountStage = $('#account_stage')[0].value;
  }

  let stages = {
    basic: 'Basic',
  };

  if (appid_result) {
    Object.assign(stages, { int: 'Int', test: 'Test', prod: 'Prod' });
  }

  if (account_data) {
    if (!(account_data.account_stage in stages)) {
      stages[account_data.account_stage] = account_data.account_stage;
    }
  }

  updateAccountStagesDropdown(stages, accountStage);
}

function updateAccountStagesDropdown(stages, account_stage) {
  const sel = document.getElementById('account_stage');

  for (let i = sel.options.length - 1; i >= 0; i--) {
    sel.remove(i);
  }

  for (let stage in stages) {
    const opt = document.createElement('option');
    opt.value = stage;
    opt.innerText = stages[stage];

    if (account_stage) {
      if (stage === account_stage || stage === 'int' || stage === 'basic') {
        opt.selected = true;
      }
    } else {
      if (stage === 'int') {
        opt.selected = true;
        account_stage = 'int';
      } else if (stage === 'basic') {
        opt.selected = true;
        account_stage = 'basic';
      }
    }
    sel.appendChild(opt);
  }
}

function remove_form_warnings() {
  $('#form-error').hide();
  $('#form-success').hide();

  editFields.forEach(function (item) {
    $('#form_group_' + item).removeClass('has-success');
    $('#form_group_' + item).removeClass('has-error');
  });
}

// validate the edit account form
export function validateAccountForm() {
  $(() => addSpinner());
  $(() => addButtonSpinner());
  const email_pattern =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const friendlyNamePattern = /^[^<>=]*$/;

  remove_form_warnings();

  if ($('#region_category')[0].value !== 'China') {
    // MASTER ACCOUNT ID
    if (!accountIdPattern.test($('#master_account')[0].value)) {
      $('#form_group_master_account').addClass('has-error');
      $('#form-error').html('<strong>Warning: </strong> Master Account must be a 12 digit long value!');
      $('#form-error').show();
      window.scrollTo(0, 0);
      return;
    } else {
      $('#form_group_master_account').addClass('has-success');
    }
  }

  // ACCOUNT ID
  if (!accountIdPattern.test($('#account_id')[0].value)) {
    $('#form_group_account_id').addClass('has-error');
    $('#form-error').html('<strong>Warning: </strong> AWS Account ID must be a 12 digit long value!');
    $('#form-error').show();
    window.scrollTo(0, 0);
    return;
  } else {
    $('#form_group_account_id').addClass('has-success');
  }

  // CHECK ROOT AND MASTER ACCOUNT STUFF ONLY FOR ACCOUNTS OUTSIDE OF CHINA
  if ($('#region_category')[0].value !== 'China') {
    // ACCOUNT ROOT EMAIL
    if (!$('#account_root_email')[0].value) {
      $('#form_group_account_root_email').addClass('has-error');
      $('#form-error').html('<strong>Warning: </strong> Root Email can not be empty!');
      $('#form-error').show();
      window.scrollTo(0, 0);
      return;
    } else {
      if (!email_pattern.test($('#account_root_email')[0].value.toLowerCase())) {
        $('#form_group_account_root_email').addClass('has-error');
        $('#form-error').html('<strong>Warning: </strong> Root Email must be an actual email address!');
        $('#form-error').show();
        window.scrollTo(0, 0);
        return;
      } else {
        $('#form_group_account_root_email').addClass('has-success');
      }
    }
  }

  // CREATION DATE
  if (!$('#creation_date')[0].value) {
    $('#form_group_creation_date').addClass('has-error');
    $('#form-error').html('<strong>Warning: </strong> Creation Date can not be empty!');
    $('#form-error').show();
    window.scrollTo(0, 0);
    return;
  } else {
    $('#form_group_creation_date').addClass('has-success');
  }

  // FRIENDLY NAME
  if (!$('#account_friendly_name')[0].value) {
    $('#form_group_account_friendly_name').addClass('has-error');
    $('#form-error').html('<strong>Warning: </strong> Friendly Name can not be empty!');
    $('#form-error').show();
    window.scrollTo(0, 0);
    return;
  } else if (!friendlyNamePattern.test($('#account_friendly_name')[0].value)) {
    $('#form_group_account_friendly_name').addClass('has-error');
    $('#form-error').html(
      '<strong>Warning: </strong> Friendly Name contains invalid characters ' +
        '(<code>&lt;</code>, <code>&gt;</code> or <code>=</code>)!'
    );
    $('#form-error').show();
    window.scrollTo(0, 0);
    return;
  } else {
    $('#form_group_account_friendly_name').addClass('has-success');
  }

  // ACCOUNT AREA
  if (!$('#account_area')[0].value) {
    $('#form_group_account_area').addClass('has-error');
    $('#form-error').html('<strong>Warning: </strong> Account Area can not be empty!');
    $('#form-error').show();
    window.scrollTo(0, 0);
    return;
  } else {
    $('#form_group_account_area').addClass('has-success');
  }

  // ACCOUNT TYPE
  if (!$('#account_type')[0].value) {
    $('#form_group_account_type').addClass('has-error');
    $('#form-error').html('<strong>Warning: </strong> Account Type can not be empty!');
    $('#form-error').show();
    window.scrollTo(0, 0);
    return;
  } else {
    $('#form_group_account_type').addClass('has-success');
  }

  // ACCOUNT STAGE
  if (!$('#account_stage')[0].value) {
    $('#form_group_account_stage').addClass('has-error');
    $('#form-error').html('<strong>Warning: </strong> Account Type can not be empty!');
    $('#form-error').show();
    window.scrollTo(0, 0);
    return;
  } else {
    $('#form_group_account_stage').addClass('has-success');
  }

  // PRIMARY RESPONSIBLE
  if (!$('#primary_responsible')[0].value) {
    $('#form_group_primary_responsible').addClass('has-error');
    $('#form-error').html('<strong>Warning: </strong> Primary Responsible can not be empty!');
    $('#form-error').show();
    window.scrollTo(0, 0);
    return;
  } else {
    if (!email_pattern.test($('#primary_responsible')[0].value.toLowerCase())) {
      $('#form_group_primary_responsible').addClass('has-error');
      $('#form-error').html('<strong>Warning: </strong> Primary Responsible must be a valid email address!');
      $('#form-error').show();
      window.scrollTo(0, 0);
      return;
    } else {
      $('#form_group_primary_responsible').addClass('has-success');
    }
  }

  // SECONDARY RESPONSIBLE
  if ($('#sec_responsible')[0].value) {
    const sec_email_result = email_pattern.test($('#sec_responsible')[0].value.toLowerCase());

    if (!sec_email_result) {
      $('#form_group_sec_responsible').addClass('has-error');
      $('#form-error').html('<strong>Warning: </strong> Secondary Responsible must be a valid email address!');
      $('#form-error').show();
      window.scrollTo(0, 0);
      return;
    } else {
      $('#form_group_sec_responsible').addClass('has-success');
    }
  } else {
    $('#form_group_sec_responsible').addClass('has-success');
  }

  // IT RESPONSIBLE
  if ($('#it_responsible')[0].value) {
    const manager_email_result = email_pattern.test($('#it_responsible')[0].value.toLowerCase());

    if (!manager_email_result) {
      $('#form_group_it_responsible').addClass('has-error');
      $('#form-error').html('<strong>Warning: </strong> IT Manager must be a valid email address!');
      $('#form-error').show();
      window.scrollTo(0, 0);
      return;
    } else {
      $('#form_group_it_responsible').addClass('has-success');
    }
  } else {
    $('#form_group_it_responsible').addClass('has-success');
  }

  const appid_pattern = /^APP-\d{3,6}$/;
  const appid_result = appid_pattern.test($('#app_id')[0].value);

  if ($('#app_id')[0].value) {
    if (!appid_result) {
      $('#form_group_app_id').addClass('has-error');
      $('#form-error').html(
        "<strong>Warning: </strong> APP-ID must be in format APP-###### (with 4 to 6 digits after 'APP-')!"
      );
      $('#form-error').show();
      window.scrollTo(0, 0);
      return;
    } else {
      $('#form_group_app_id').addClass('has-success');
    }
  } else {
    $('#form_group_app_id').addClass('has-warning');
  }

  const appdid_pattern = /^APPD-\d{5,6}$/;
  const appdid_result = appdid_pattern.test($('#appd_id')[0].value);

  if ($('#appd_id')[0].value) {
    if (!appdid_result) {
      $('#form_group_appd_id').addClass('has-error');
      $('#form-error').html(
        "<strong>Warning: </strong> APPD-ID must be in format APPD-###### (with 5 to 6 digits after 'APPD-')!"
      );
      $('#form-error').show();
      window.scrollTo(0, 0);
      return;
    } else {
      $('#form_group_appd_id').addClass('has-success');
    }
  } else {
    $('#form_group_appd_id').addClass('has-warning');
  }

  const cost_center_pattern = /^\d{4}$/;
  const cost_center_result = cost_center_pattern.test($('#cost_center')[0].value);

  if ($('#cost_center')[0].value) {
    if (!cost_center_result) {
      $('#form_group_cost_center').addClass('has-error');
      $('#form-error').html('<strong>Warning: </strong> Cost Center must be in format #### (with 4 digits)!');
      $('#form-error').show();
      window.scrollTo(0, 0);
      return;
    } else {
      $('#form_group_cost_center').addClass('has-success');
    }
  } else {
    $('#form_group_cost_center').addClass('has-warning');
  }

  const pso_pattern =
    /(^[A-Z]-[A-Z][A-Z0-9]{3}$)|(^[A-Z]-[A-Z][A-Z0-9]{3}-[A-Z,0-9]{2}-[A-Z,0-9]{2}$)|(^[A-Z]-[A-Z][A-Z0-9]{3}-[A-Z,0-9]{2}-[A-Z,0-9]{2}-\d{4}$)/;
  const pso_result = pso_pattern.test($('#psp')[0].value);

  if ($('#psp')[0].value) {
    if (!pso_result) {
      $('#form_group_pso').addClass('has-error');
      $('#form-error').html(
        '<strong>Warning: </strong> PSO/PSP must be in format one of the format: (^[A-Z]-[A-Z][A-Z0-9]{3}$) or (^[A-Z]-[A-Z][A-Z0-9]{3}-[A-Z,0-9]{2}-[A-Z,0-9]{2}$) or (^[A-Z]-[A-Z][A-Z0-9]{3}-[A-Z,0-9]{2}-[A-Z,0-9]{2}-[0-9]{4}$)'
      );
      $('#form-error').show();
      window.scrollTo(0, 0);
      return;
    } else {
      $('#form_group_pso').addClass('has-success');
    }
  } else {
    $('#form_group_pso').addClass('has-warning');
  }

  // update dynamodb when all values are verified successfully
  updateAccountInDB();
}

/**
 * API gateway call to insert/update the account information in dynamodb
 */
function updateAccountInDB() {
  const accountAreaChanged = editAccountInitialData['account_area'] !== $('#account_area').val();
  const accountTypeChanged = editAccountInitialData['account_type'] !== $('#account_type').val();
  const requiresOrder = accountAreaChanged || accountTypeChanged;

  const { update_account_information: update_settings } = editAccountSettings;
  const enabledFields = update_settings?.attributes?.enabled || [
    'account_friendly_name',
    'description',
    'it_responsible',
    'primary_responsible',
    'sec_responsible',
  ];

  const updatePayloadFields = enabledFields.filter(
    fieldname => ['account_area', 'account_type'].indexOf(fieldname) === -1
  );

  const displaySwal = errorMessage => {
    const orderContainer = document.createElement('div');
    orderContainer.style = 'margin: 2rem 2rem 0;';

    if (requiresOrder) {
      const orderReason = document.createElement('textarea');
      orderReason.setAttribute('rows', '4');
      orderReason.className = 'swal-textarea';

      const textareaInputListener = evt => {
        if (evt.target.value && evt.target.value.length > 10) {
          swal.setActionValue({ confirm: { reason: evt.target.value } });
        }
      };

      const orderRequiredExplanation =
        '<strong>Changing the account area or account type requires the creation of an order.<br/>For a timely approval, you are required to provide a reason for the change:</strong>';
      if (errorMessage) {
        orderContainer.innerHTML = `<div class="alert alert-danger swal-error-container" role="alert">${errorMessage}</div>${orderRequiredExplanation}`;
        orderReason.addEventListener('input', evt => {
          textareaInputListener(evt);
          const errorDiv = document.querySelector('.swal-error-container');
          if (errorDiv) errorDiv.style.display = 'none';
        });
      } else {
        orderContainer.innerHTML = orderRequiredExplanation;
        orderReason.addEventListener('input', textareaInputListener);
      }
      orderContainer.appendChild(orderReason);
    } else {
      orderContainer.display = 'none';
    }

    let accountInformation = {};
    updatePayloadFields.forEach(field => {
      const element = $('#' + field);
      if (!element.attr('disabled') && element.val()) {
        accountInformation[field] = element.val();
      }
    });

    let accountValuesChanged;
    Object.entries(accountInformation).forEach(([key, value]) => {
      if (editAccountInitialData[key] !== value) accountValuesChanged = true;
    });

    if (accountValuesChanged || requiresOrder) {
      swal({
        closeOnClickOutside: false,
        title: 'Are you sure?',
        content: {
          element: orderContainer,
        },
        text: `Are you sure you want to save this info into the database?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Cancel',
            value: null,
            visible: true,
          },
          confirm: {
            text: 'Update',
            value: {},
            visible: true,
          },
        },
      }).then(function (response) {
        if (response) {
          if (requiresOrder) {
            if (!response.reason) {
              displaySwal(`'Reason for the Order' is a required field`);
              return;
            }
            const orderPayload = {
              action: 'update-account-area',
              reason: response.reason,
              description: `Update Account ${editAccountInitialData['account_id']} - ${[
                accountAreaChanged ? 'Account Area' : null,
                accountTypeChanged ? 'Account Type' : null,
              ]
                .filter(elem => elem !== null)
                .join(' and ')}`,
              account_id: editAccountInitialData['account_id'],
              account_type: accountTypeChanged ? $('#account_type')[0].value : undefined,
              account_area: accountAreaChanged ? $('#account_area')[0].value : undefined,
            };
            apiAxios
              .post('/orders', {
                action: 'create-order',
                payload: orderPayload,
              })
              .then(orderResponse => {
                addToast('Create Order', orderResponse?.data?.message, 6000);
                if (accountValuesChanged) {
                  sendAccountUpdateRequest(editAccountInitialData['account_id'], accountInformation);
                } else {
                  hideAccountEditForm();
                }
              })
              .catch(showErrorFromApiOperation('Error creating account update Order'))
              .finally(() => removeSpinners());
          } else {
            sendAccountUpdateRequest(editAccountInitialData['account_id'], accountInformation);
          }
        } else {
          removeSpinners();
        }
      });
    } else {
      displayInfoPopup(`The account dataset will not change.`, 'No changes found').then(() => removeSpinners());
    }
  };

  displaySwal();
}

function sendAccountUpdateRequest(accountId, accountInformation) {
  apiAxios
    .put('/accounts/' + accountId, accountInformation)
    .then(responsePut => {
      updateAccountPage(accountId, responsePut);
    })
    .catch(showErrorFromApiOperation('Error updating Account'))
    .finally(() => removeSpinners());
}

// list accounts table

export const accountsTableColumns = [
  { id: 'select' },
  { id: 'region_category', name: 'Region Category' },
  { id: 'master_account', name: 'Master Account ID' },
  { id: 'account_id', name: 'Account ID' },
  { id: 'account_root_email', name: 'Root Account' },
  { id: 'creation_date', name: 'Creation Date' },
  { id: 'fpc_status', name: 'FPC Status' },
  { id: 'account_friendly_name', name: 'Account Friendly Name' },
  { id: 'description', name: 'Description' },
  { id: 'account_area', name: 'Account Area' },
  { id: 'account_type', name: 'Account Type' },
  { id: 'account_stage', name: 'Account Stage' },
  { id: 'primary_responsible', name: 'Primary Responsible' },
  { id: 'sec_responsible', name: 'Secondary Responsible' },
  { id: 'it_responsible', name: 'IT Responsible' },
  { id: 'support_plan', name: 'AWS Support Plan' },
  { id: 'app_id', name: 'APP-ID' },
  { id: 'appd_id', name: 'APPD-ID' },
  { id: 'cost_center', name: 'Cost Center' },
  { id: 'psp', name: 'PSO/PSP' },
  { id: 'month-2', name: 'Costs two months ago' },
  { id: 'month-1', name: 'Costs last month' },
  { id: 'month-0', name: 'Costs current month' },
  { id: 'federated_user_roles', name: 'AD accessible roles (central)' },
  { id: 'cdh_user_roles', name: 'CDH accessible roles (decentral)' },
  { id: 'org_id', name: 'Org Id' },
  { id: 'org_name', name: 'Org Name' },
  { id: 'org_ou_id', name: 'Org Unit Id' },
  { id: 'org_ou_name', name: 'Org Unit Name' },
  { id: 'org_status', name: 'Org Status' },
  { id: 'org_status_update', name: 'Last Org Update (UTC)' },
  { id: 'access_status', name: 'Access Check' },
  { id: 'access_update', name: 'Last Access Update (UTC)' },
  { id: 'approval_expires', name: 'User Confirmation Expiration' },
  { id: 'app_criticality', name: 'App Criticality' },
  { id: 'itsm_service', name: 'ITSM Service' },
  { id: 'actions', name: 'Actions' },
];

const dropdownColumns = [
  'region_category',
  'master_account',
  'fpc_status',
  'account_area',
  'account_type',
  'account_stage',
  'org_id',
  'org_name',
  'org_ou_id',
  'org_ou_name',
  'org_status',
  'access_status',
  'support_plan',
  'app_criticality',
  'itsm_service',
];

const searchColumns = [
  'account_id',
  'account_root_email',
  'creation_date',
  'account_friendly_name',
  'description',
  'primary_responsible',
  'sec_responsible',
  'it_responsible',
  'app_id',
  'appd_id',
  'cost_center',
  'psp',
  'org_status_update',
  'access_update',
  'approval_expires',
];

let showAllAccounts = false;
let showDeletedAccounts = false;

function loadAccountsData(tableId, headers, forceReload) {
  // addSpinner must be called within the context of jQuery. Otherwise,
  // the spinner won't be visible on the page.
  $(() => addSpinner());
  $(() => addTableSpinner());

  if (forceReload) {
    headers = { ...headers, 'Cache-Control': 'max-age=0, must-revalidate' };
  }
  const attributes = retrieveVisibleFields('#' + tableId, accountsTableColumns);

  if (attributes.indexOf('fpc_status') === -1) attributes.push('fpc_status');
  if (attributes.indexOf('account_id') === -1) attributes.push('account_id');

  const params = {
    attribute_names: attributes.join(),
    all: showAllAccounts,
    deleted: showDeletedAccounts,
  };

  apiAxios
    .get('/accounts', { headers, params })
    .then(response => {
      const { account_data: accounts } = response.data;
      reloadAccountTable(accounts);
      updateAccountSummary(accounts);
    })
    .catch(showErrorFromApiOperation('Error fetching list of accounts'))
    .finally(() => {
      removeSpinners();
    });
}

function reloadAccountTable(data_array) {
  const table = $('#table-accounts');
  const dt = table.DataTable().clear().draw();
  const dtWTF = table.dataTable();

  if (!data_array || !Array.isArray(data_array)) {
    $('#table-accounts').hide();
    $('#table-dropdown-button').hide();
    $('#noaccountalert').show();
    removeSpinners();
    return;
  }

  dt.rows.add(data_array).draw();

  const columnsState = dtWTF.api().state().columns;
  dt.columns().every(function (col) {
    const that = this;
    const column = this;
    const title = column.header().innerHTML;
    const dataSource = column.dataSrc();

    if (dropdownColumns.indexOf(dataSource) > -1) {
      const select = $('<select><option value=""></option></select>')
        .appendTo($(column.footer()).empty())
        .on('change', function () {
          const val = $.fn.dataTable.util.escapeRegex($(this).val());
          column.search(val ? '^' + val + '$' : '', true, false).draw();
        });

      column
        .data()
        .unique()
        .sort()
        .each(function (data) {
          let opt = document.createElement('option');
          opt.innerText = data;
          opt.value = data;
          select.append(opt);

          if (
            data &&
            columnsState[col].search &&
            columnsState[col].search.search &&
            data.match(columnsState[col].search.search)
          ) {
            opt.selected = true;
          }
        });
    }

    if (searchColumns.indexOf(dataSource) > -1) {
      $(column.footer()).html('<input type="text" placeholder="Search ' + title + '" />');

      if (columnsState[col].search && columnsState[col].search.search) {
        column.footer().lastChild.value = columnsState[col].search.search;
      }

      $('input', that.footer()).on('keyup change', function () {
        if (that.search() !== this.value) {
          that.search(this.value).draw();
        }
      });
    }
  });

  $(window).trigger('resize');

  removeSpinners();
}

export async function initAccountTable(tableId) {
  configureTableColumns(tableId, accountsTableColumns);

  const formatter = new Intl.NumberFormat(CONF.language, {
    style: 'currency',
    currency: CONF.currency,
    maximumFractionDigits: 2,
  });

  initDataTable(
    tableId,
    'lCfrtpBi',
    [
      {
        extend: 'excelHtml5',
        text: 'Export Excel',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as Excel file',
      },
      {
        extend: 'csvHtml5',
        text: 'Export CSV',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as CSV file',
      },
      {
        extend: 'copyHtml5',
        text: 'Copy',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Copy the visible columns into your clipboard',
      },
      {
        extend: 'resetTable',
        ajaxReload: false,
        titleAttr: 'Reset all filters in the table footer',
      },
      {
        extend: 'reloadTable',
        text: 'Reload Accounts',
        ajaxReload: false,
        methodReload: loadAccountsData,
        titleAttr: 'Reload AWS accounts (no-cache)',
      },
    ],
    [
      {
        // Column 0
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: null,
        name: 'select',
        class: 'details-control',
        width: '20px',
      },
      {
        // Column 1
        visible: false,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'region_category',
        name: 'region_category',
        title: 'Region Category',
        createdCell: function (td, cellData) {
          addCopyButton(td);
          if (cellData === 'China') {
            $(td).addClass('portal-warning');
          }
        },
      },
      {
        // Column 2
        visible: false,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'master_account',
        name: 'master_account',
        title: 'Master Account Id',
        createdCell: function (td, cellData) {
          addCopyButton(td);
          addLoadAccountDetailsButton(td);

          if (!accountIdPattern.test(cellData)) {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        // Column 3
        visible: true,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'account_id',
        name: 'account_id',
        title: 'Account Id',
        createdCell: function (td, cellData) {
          addCopyButton(td);
          addLoadAccountDetailsButton(td);

          if (!accountIdPattern.test(cellData)) {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        // Column 4
        visible: false,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'account_root_email',
        name: 'account_root_email',
        title: 'Root Account',
        createdCell: addCopyButton,
      },
      {
        // Column 5
        visible: false,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'creation_date',
        name: 'creation_date',
        title: 'Creation Date',
        createdCell: addCopyButton,
      },
      {
        // Column 6
        visible: false,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'fpc_status',
        name: 'fpc_status',
        title: 'FPC Status',
        createdCell: addCopyButton,
      },
      {
        // Column 7
        visible: true,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'account_friendly_name',
        name: 'account_friendly_name',
        title: 'Account Friendly Name',
        createdCell: addCopyButton,
      },
      {
        // Column 8
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'description',
        name: 'description',
        title: 'Description',
        createdCell: addCopyButton,
      },
      {
        // Column 9
        visible: false,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'account_area',
        name: 'account_area',
        title: 'Account Area',
        createdCell: addCopyButton,
      },
      {
        // Column 10
        visible: true,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'account_type',
        name: 'account_type',
        title: 'Account Type',
        createdCell: addCopyButton,
      },
      {
        // Column 11
        defaultContent: 'unknown',
        visible: false,
        orderable: true,
        searchable: true,
        data: 'account_stage',
        name: 'account_stage',
        title: 'Account Stage',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          if (cellData === 'basic') {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        // Column 12
        visible: false,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'primary_responsible',
        name: 'primary_responsible',
        title: 'Primary Responsible',
        createdCell: addCopyButton,
      },
      {
        // Column 13
        visible: false,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'sec_responsible',
        name: 'sec_responsible',
        title: 'Secondary Responsible',
        createdCell: addCopyButton,
      },
      {
        // Column 14
        visible: false,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'it_responsible',
        name: 'it_responsible',
        title: 'IT Responsible',
        createdCell: addCopyButton,
      },
      {
        // Column 15
        defaultContent: 'unknown',
        visible: false,
        orderable: true,
        data: 'support_plan',
        name: 'support_plan',
        title: 'AWS Support Plan',
        createdCell: addCopyButton,
      },
      {
        // Column 16
        visible: false,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'app_id',
        name: 'app_id',
        title: 'APP ID',
        createdCell: function (td, cellData) {
          // check for empty values
          if (!cellData) {
            $(td).text('unknown');
            $(td).addClass('portal-danger');
          }

          addCopyButton(td);
          // regex for patterns like this
          // APP-01234
          // APP-0123456
          const appid_pattern = /^APP-\d{4,7}$/;
          if (!appid_pattern.test(cellData)) {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        // Column 17
        visible: false,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'appd_id',
        name: 'appd_id',
        title: 'APPD ID',
        createdCell: function (td, cellData) {
          // check for empty values
          if (!cellData) {
            $(td).text('unknown');
            $(td).addClass('portal-danger');
          }

          addCopyButton(td);
          const appdid_pattern = /^APPD-\d{5,6}$/;
          if (!appdid_pattern.test(cellData)) {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        // Column 18
        visible: false,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'cost_center',
        name: 'cost_center',
        title: 'Cost Center',
        createdCell: function (td, cellData) {
          // check for empty values
          if (!cellData) $(td).text('unknown');

          addCopyButton(td);
        },
      },
      {
        // Column 19
        visible: false,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'psp',
        name: 'psp',
        title: 'PSO',
        createdCell: function (td, cellData) {
          // check for empty values
          if (!cellData) $(td).text('unknown');

          addCopyButton(td);
        },
      },
      {
        // Column 20
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'month-2',
        name: 'month-2',
        title: 'Costs Two months ago',
        createdCell: addCopyButton,
      },
      {
        // Column 21
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'month-1',
        name: 'month-1',
        title: 'Costs last month',
        createdCell: addCopyButton,
      },
      {
        // Column 22
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'month-0',
        name: 'month-0',
        title: 'Costs current month',
        createdCell: addCopyButton,
      },
      {
        // Column 23
        visible: false,
        defaultContent: 0,
        orderable: true,
        searchable: true,
        data: null,
        name: 'federated_user_roles',
        title: 'AD accessible roles (central)',
        render: function (data) {
          if ('federated_user_roles' in data) {
            return data['federated_user_roles'].length;
          }
        },
        createdCell: addCopyButton,
      },
      {
        // Column 24
        visible: false,
        defaultContent: 0,
        orderable: true,
        searchable: true,
        data: null,
        name: 'cdh_user_roles',
        title: 'AD accessible roles (decentral)',
        render: function (data) {
          if ('cdh_user_roles' in data) {
            return data['cdh_user_roles'].length;
          }
        },
        createdCell: addCopyButton,
      },
      {
        // Column 25
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'org_id',
        name: 'org_id',
        title: 'Org Id',
        createdCell: addCopyButton,
      },
      {
        // Column 26
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'org_name',
        name: 'org_name',
        title: 'Org Name',
        createdCell: addCopyButton,
      },
      {
        // Column 27
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'org_ou_id',
        name: 'org_ou_id',
        title: 'Org Unit Id',
        createdCell: addCopyButton,
      },
      {
        // Column 28
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'org_ou_name',
        name: 'org_ou_name',
        title: 'Org Unit Name',
        createdCell: addCopyButton,
      },
      {
        // Column 29
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'org_status',
        name: 'org_status',
        title: 'Org Status',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          if (cellData === 'ACTIVE') {
            $(td).addClass('portal-success');
          } else {
            $(td).addClass('portal-neutral');
          }
        },
      },
      {
        // Column 30
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'org_status_update',
        name: 'org_status_update',
        title: 'Last Org Update (UTC)',
        createdCell: addCopyButton,
      },
      {
        // Column 31
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'access_status',
        name: 'access_status',
        title: 'Access Check',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          if (cellData === 'Success') {
            $(td).addClass('portal-success');
          } else {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        // Column 32
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'access_update',
        name: 'access_update',
        title: 'Last Access Update (UTC)',
        createdCell: addCopyButton,
      },
      {
        // Column 33
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'approval_expires',
        name: 'approval_expires',
        title: 'User Confirmation Expiration',
        createdCell: addCopyButton,
        render: function (data, type, row) {
          if (type === 'display' && checkResponsibility(row, COGNITO.user.email) && row.fpc_status !== 'deleted') {
            try {
              const expirationDate = new Date(data);
              const daysDifference = Math.ceil(
                (expirationDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
              );
              let daysClass = 'portal-success';
              if (daysDifference <= 28) {
                daysClass = 'portal-danger';
              } else if (daysDifference < 50) {
                daysClass = 'portal-warning';
              }
              return (
                '<a href="?account_id=' +
                row.account_id +
                '#confirmusers" target="_blank" title="Go to User Confirmation (only for Account Responsibles)">' +
                expirationDate.toISOString().substring(0, 10) +
                ' (<strong class="color: ' +
                daysClass +
                '">' +
                daysDifference +
                '</strong> days left) ' +
                '<i class="fas fa-external-link-alt" style="margin-left: 0.5em, font-size: smaller"></i></a>'
              );
            } catch {
              // return data;
              return (
                '<a href="?account_id=' +
                row.account_id +
                '#confirmusers" target="_blank" title="Go to User Confirmation (only for Account Responsibles)">Confirm Users ' +
                '<i class="fas fa-external-link-alt" style="margin-left: 0.5em, font-size: smaller"></i></a>'
              );
            }
          } else if (
            type === 'sort' &&
            !data &&
            checkResponsibility(row, COGNITO.user.email) &&
            row.fpc_status !== 'deleted'
          ) {
            return '_';
          } else {
            return data;
          }
        },
      },
      {
        // Column 34
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'app_criticality',
        name: 'app_criticality',
        title: 'App Criticality',
        createdCell: addCopyButton,
      },
      {
        // Column 35
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'itsm_service',
        name: 'itsm_service',
        title: 'ITSM Service',
        createdCell: addCopyButton,
      },
      {
        // Column 36
        visible: true,
        defaultContent: '',
        orderable: false,
        data: null,
        name: 'actions',
        title: 'Actions',
        createdCell: createActionsButtons,
        class: 'details-edit',
        width: '50px',
      },
    ],
    function (row, data) {
      if (data.fpc_status === 'deleted') {
        $(row).addClass('row-deleted');
      } else if (data.fpc_status === 'halt') {
        $(row).addClass('row-inactive');
      }
    },
    {
      columnDefs: [
        {
          targets: [20, 21, 22],
          render: function (data) {
            if (!data || data === true) {
              return '-';
            } else {
              return formatter.format(parseFloat(data));
            }
          },
        },
        {
          targets: '_all',
          render: {
            display: encodeEntities,
          },
        },
      ],
      order: [[3, 'asc']],
      footerCallback: function (_row, _data, _start, _end, _display) {
        const api = this.api();

        // Total over all pages
        const total = api.column('month-0:name').data().reduce(reduceColumnValues, 0);
        const total_1 = api.column('month-1:name').data().reduce(reduceColumnValues, 0);
        const total_2 = api.column('month-2:name').data().reduce(reduceColumnValues, 0);
        // Total over this page
        const pageTotal = api.column('month-0:name', { page: 'current' }).data().reduce(reduceColumnValues, 0);
        const pageTotal_1 = api.column('month-1:name', { page: 'current' }).data().reduce(reduceColumnValues, 0);
        const pageTotal_2 = api.column('month-2:name', { page: 'current' }).data().reduce(reduceColumnValues, 0);

        // Update footer
        $(api.column('month-0:name').footer()).html(
          formatter.format(pageTotal) + ' (' + formatter.format(total) + ' total)'
        );
        $(api.column('month-1:name').footer()).html(
          formatter.format(pageTotal_1) + ' (' + formatter.format(total_1) + ' total)'
        );
        $(api.column('month-2:name').footer()).html(
          formatter.format(pageTotal_2) + ' (' + formatter.format(total_2) + ' total)'
        );
      },
    }
  );

  const permissions = localStorage.permissions;
  const account_areas = localStorage.account_areas;
  const hasPermissionToViewAll =
    checkValueAgainstList('manage_accounts', permissions) ||
    checkValueAgainstList('view_all_accounts', permissions) ||
    account_areas;

  $('#show-all-accounts-div').toggleClass('d-none', !hasPermissionToViewAll);

  $('#show-all-accounts-switch')
    .attr('checked', showAllAccounts)
    .on('change', function () {
      if (showAllAccounts !== this.checked) {
        showAllAccounts = this.checked;
        loadAccountsData(tableId);
      }
    });

  $('#show-deleted-accounts-switch')
    .attr('checked', showDeletedAccounts)
    .on('change', function () {
      if (showDeletedAccounts !== this.checked) {
        showDeletedAccounts = this.checked;
        loadAccountsData(tableId);
      }
    });

  addTableButtons();

  $(`select[name="${tableId}_fields"]`).on('hide.bs.select', function () {
    loadAccountsData(tableId, null, true);
  });

  rowDetailsClickHandler({ tableId: tableId, rowDetailCallback: formatAccountRow });
  loadAccountsData(tableId);
}

function addTableButtons() {
  const permissions = localStorage.permissions ? localStorage.permissions : false;
  const dt = $('#table-accounts').DataTable({ retrieve: true });
  let buttons = 0; // Add buttons before the already existing buttons

  if (checkValueAgainstList('manage_accounts', permissions)) {
    dt.button().add(buttons, {
      text: 'Trigger Account Updates',
      action: triggerAllAccountsAccDetailsUpdate,
      titleAttr: 'Collect account information like the alternate contacts or the support level.',
    });
    buttons++;
  }

  if (checkValueAgainstList('manage_organizations', permissions)) {
    dt.button().add(buttons, {
      text: 'Update Organizational Details',
      action: triggerAllAccountsOrgaDetailsUpdate,
      titleAttr: 'Collect all AWS accounts from AWS Organizations.',
    });
  }
}

function createActionsButtons(td, _cellData, rowData, _row, _col) {
  const buttons = $('<div class="table-action-button-group">').appendTo(td);

  buttons.append(
    $('<button class="btn-datatable" data-bs-toggle="tooltip" title="Account Details">')
      .append('<i class="fas fa-external-link-alt">')
      .on('click', () => {
        window.open('?account_id=' + rowData.account_id + '#accountdetails', '_blank');
      })
  );

  buttons.append(
    $('<button class="btn-datatable" data-bs-toggle="tooltip" title="Manage and Confirm Users">')
      .append('<i class="fas fa-users">')
      .on('click', () => {
        window.open('?account_id=' + rowData.account_id + '#confirmusers', '_blank');
      })
  );
}

export async function formatAccountRow(row) {
  const data = row.data();
  Promise.all([baseApiAxios.getAccount(data.account_id), baseApiAxios.getAccountPermissions(data.account_id)])
    .then(response => {
      const account_details = { ...response[0], ...response[1].permanent_roles };
      const temp_access_roles = response[1].temporary_roles || [];

      row
        .child(<AccountTabs data={account_details} tempAccessRoles={temp_access_roles} isAccountDetailsPage={false} />)
        .show();
      row.child()[0].setAttribute('class', 'rowDetails');
    })
    .catch(err => {
      showErrorFromApiOperation('Failed to fetch account details')(err);
    })
    .finally(() => {
      removeSpinners();
    });
}

export function formatChildRowListAccountsHeader(data, infoContainer) {
  // Warning if account is marked for deletion
  listAccountsChildRowAddWarning(infoContainer, data);

  // Orders related to account actions, like 'account-close' or 'ou-move'
  listAccountsChildRowAddOrders(infoContainer, data);
}

export function formatChildRowListAccountsLeftSide(data, container) {
  // LEFT - ACCOUNT DETAILS
  const accountContainer = document.createElement('div');
  accountContainer.setAttribute('class', 'detailsContent');
  container.appendChild(accountContainer);

  const header = document.createElement('h4');
  header.innerText = 'AWS Account Details';
  accountContainer.appendChild(header);

  const accountContainerDetails = document.createElement('div');
  accountContainer.appendChild(accountContainerDetails);

  let fields = [];
  fields = fields.concat(accountInfoFields);
  fields = fields.concat(accountOrgInfoFields);

  createAndAddInfoTable(data, fields, accountContainerDetails);
}

function listAccountsChildRowAddWarning(container, data) {
  if (data.marked_for_deletion && data.fpc_status !== 'deleted') {
    const info = document.createElement('div');
    info.setAttribute('class', 'detailsContent');
    container.appendChild(info);

    const msg = document.createElement('div');
    msg.setAttribute('style', 'color: black;');
    msg.innerHTML = '<h3><strong>Warning:</strong> AWS Account is marked for deletion!</h3>';
    info.appendChild(msg);
  }
}

function listAccountsChildRowAddOrders(container, data) {
  if (data.orders && Object.getOwnPropertyNames(data.orders).length > 0) {
    const ordersContainer = document.createElement('div');
    ordersContainer.setAttribute('class', 'detailsContent');
    container.appendChild(ordersContainer);

    const ordersTable = document.createElement('table');
    ordersTable.setAttribute('class', 'table table-hover dataTable row-border nowrap compressed');
    ordersContainer.appendChild(ordersTable);

    const orderColumns = [
      { Name: 'Order Id', Value: 'id' },
      { Name: 'Action', Value: 'action' },
      { Name: 'Proposer', Value: 'proposer' },
      { Name: 'Creation Date', Value: 'creation_date' },
    ];

    const ordersTableHead = document.createElement('thead');
    ordersTable.appendChild(ordersTableHead);

    orderColumns.forEach(function (item) {
      const th = document.createElement('th');
      th.setAttribute('class', 'compressed');
      th.innerText = item['Name'];
      ordersTableHead.appendChild(th);
    });

    const ordersTableBody = document.createElement('tbody');
    ordersTable.appendChild(ordersTableBody);

    for (let key in data.orders) {
      const tr = document.createElement('tr');
      tr.setAttribute('role', 'row');
      tr.setAttribute('class', 'compressed');

      orderColumns.forEach(function (item) {
        const td = document.createElement('td');

        if (item['Value'] === 'id') {
          td.innerText = key;
          addCopyButton(td);
          addLoadOrderDetailsButton(td);
        } else {
          td.innerText = data.orders[key][item['Value']];
          addCopyButton(td);
        }

        tr.appendChild(td);
      });

      ordersTableBody.appendChild(tr);
    }
  }
}
