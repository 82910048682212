import {
  changeDNSText,
  checkHostedZone,
  checkValidityHostedZone,
  notifyDhcpChange,
  showHostedZoneAvailability,
  submitHostedZoneOrder,
  updateAvailableZoneTypesDropdown,
  updateZoneType,
} from './js/portal/zones';
import AccountDropdown from './jsx/components/forms/AccountDropdown';
import { addButtonSpinner, addSpinner } from './js/portal/sidebar';
import { hasAccess } from './js/portal/main';

export default function HostedZonesCreatePage() {
  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="create-route53-hosted-zone-form" id="create-route53-hosted-zone-form" method="POST">
          <div class="card form-card">
            {/* CARD HEADER */}
            <div class="card-header">Create new Route 53 Hosted Zone</div>

            {/* CARD BODY */}
            <div class="card-body">
              <div id="form-error" class="alert alert-danger" style="display: none">
                <ul />
              </div>

              <div id="form-success" class="alert alert-success" style="display: none">
                <ul />
              </div>

              <div id="infobox" class="card infobox">
                <div class="card-body">
                  <p>
                    With this form you can request a new Route 53 Private or Public Hosted Zone for your target AWS
                    Account. We do only support the domains that you can find and select below. We have no authority to
                    manage other domains (for example bmwgroup.net). If you need DNS records for other BMW domains than
                    listed below, please get in contact with the BMW on premise DNS department.
                  </p>
                </div>
              </div>

              {/* AWS ACCOUNT ID */}
              <AccountDropdown
                id="aws-account-id"
                onchange={(_eventTypeId, _eventCategory, _selectedOption, accountId, accountType) => {
                  document.getElementById('submitButton').disabled = !hasAccess(accountId, ['manage_dns']);
                  updateAvailableZoneTypesDropdown(null, null, null, null, accountType);
                }}
                short
                lookup
              />

              {/* HOSTED ZONE TYPE */}
              <div class="form-group row" id="zone-type-container" style="display: none;">
                <label for="zone-type" class="col-form-label">
                  Hosted Zone Type
                </label>
                <div class="col">
                  <select
                    required
                    class="form-control form-select selectpicker"
                    data-live-search="true"
                    data-show-subtext="true"
                    id="zone-type"
                    name="zone-type"
                    data-dropdown-align-right="auto"
                    onchange={() => updateZoneType()}>
                    <option value="" disabled selected>
                      - Please select the Route 53 Hosted Zone type -
                    </option>
                  </select>

                  {/* Hosted Zone Type Infobox */}
                  <div class="accordion infobox mt-3 mb-1" id="zone-type-infobox">
                    {/* Accordion Item Private Hosted Zone */}
                    <div class="accordion-item">
                      <h4 class="accordion-header" id="zoneTypeInfoHeaderPrivate">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#zoneTypeInfoCollapsePrivate"
                          aria-expanded="true"
                          aria-controls="zoneTypeInfoCollapsePrivate">
                          <i class="fas fa-info-circle" /> &nbsp;Private Hosted Zone
                        </button>
                      </h4>
                      <div
                        id="zoneTypeInfoCollapsePrivate"
                        class="accordion-collapse collapse show"
                        aria-labelledby="zoneTypeInfoHeaderPrivate"
                        data-bs-parent="#zone-type-infobox">
                        <div class="accordion-body">
                          <p>
                            Private Hosted Zones are only available for account type(s){' '}
                            <strong id="zone-type-infobox-private-account-types">default</strong>. Find more information
                            about the{' '}
                            <a
                              href="https://developer.bmw.com/docs/public-cloud-platform-aws/1_beforeyoustart/platformdoc/cloudroom/cloudroom/#overview-bmw-customer-accounts"
                              target="_blank"
                              rel="noopener noreferer">
                              BMW AWS account types
                            </a>
                            .
                          </p>
                          <p>
                            As we need to provide a shared services infrastructure (DNS, Direct Connect, Proxy, ...) in
                            every region that will be connected with the BMW on premise network, we only support limited
                            number of AWS regions. Find more information about the{' '}
                            <a
                              href="https://developer.bmw.com/docs/public-cloud-platform-aws/1_beforeyoustart/platformdoc/network/hybrid_network/hybrid_network/"
                              target="_blank"
                              rel="noopener noreferer">
                              supported regions for Private Hosted Zones
                            </a>
                            .
                          </p>
                          <p id="infobox" class="card infobox mt-3">
                            <div class="card-body">
                              <strong>Attention:</strong> Some services like EKS (4wheels Guided is based on EKS), have
                              a limitation of 46 characters for the length of the FQDN (RFC-1123).
                              <br />
                              <br />
                              <strong>Attention:</strong> A Route53 Private Hosted Zone with an underscore ( _ ) will
                              not be selectable in the 4wheels Guided order page. Although AWS Route53 supports the
                              usage of underscores ( _ ) in the DNS Prefix, this is an unsupported character in the
                              official DNS RFC (RFC-1034/RFC-1035). Using an underscore is not supported by all
                              (external) services, for example BMW PKI / CLCM.
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Accordion Item Public Hosted Zones */}
                    <div class="accordion-item">
                      <h4 class="accordion-header" id="zoneTypeInfoHeaderPublic">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#zoneTypeInfoCollapsePublic"
                          aria-expanded="false"
                          aria-controls="zoneTypeInfoCollapsePublic">
                          <i class="fas fa-info-circle" /> &nbsp;Public Hosted Zones
                        </button>
                      </h4>

                      <div
                        class="accordion-collapse collapse"
                        id="zoneTypeInfoCollapsePublic"
                        aria-labelledby="zoneTypeInfoHeaderPublic"
                        data-bs-parent="#zone-type-infobox">
                        <div class="accordion-body">
                          <p>
                            Public Hosted Zones are only available for accounts with account type(s){' '}
                            <strong id="zone-type-infobox-public-account-types">default</strong>. Find more information
                            about the{' '}
                            <a
                              href="https://developer.bmw.com/docs/public-cloud-platform-aws/1_beforeyoustart/platformdoc/cloudroom/cloudroom/#overview-bmw-customer-accounts"
                              target="_blank"
                              rel="noopener noreferer">
                              BMW AWS account types
                            </a>
                            .
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* PRIVATE HOSTED ZONE REGION */}
              <div class="form-group row" id="private-zone-region" style="display: none">
                <label for="region" class="col-form-label">
                  Region
                </label>
                <div class="col">
                  <select
                    class="form-control form-select selectpicker"
                    data-live-search="true"
                    data-show-subtext="true"
                    data-size="10"
                    id="region"
                    name="region"
                    onchange={changeDNSText}
                  />
                </div>
              </div>

              {/* DNS PREFIX */}
              <div class="form-group row" id="domain-name" style="display: none">
                <label class="col-form-label">DNS Prefix</label>
                <div class="col">
                  <div class="input-group" id="dns-prefix-container">
                    <input
                      type="text"
                      class="form-control"
                      id="dns-prefix"
                      name="dns-prefix"
                      aria-describedby="dns-prefix-valid-feedback dns-prefix-invalid-feedback"
                      placeholder="example"
                      regex-string="^[a-z0-9][a-z0-9._-]{1,200}[a-z0-9]$"
                      onchange={checkValidityHostedZone}
                    />
                    <span class="input-group-text fw-bold" id="dns-suffix">
                      .
                    </span>
                    <button
                      class="btn btn-success"
                      type="button"
                      id="network-get-free"
                      onclick={() => checkHostedZone(showHostedZoneAvailability)}>
                      Check Availability
                    </button>
                  </div>
                  <div class="valid-feedback" id="dns-prefix-valid-feedback">
                    Domain is valid
                  </div>
                  <div class="invalid-feedback" id="dns-prefix-invalid-feedback">
                    Domain is already used
                  </div>

                  <div id="infobox" class="card infobox mt-3">
                    <div class="card-body">
                      <strong>Attention:</strong> Some services or products are limited in the supported length of the
                      fully qualified domain name (fqdn). For example, some services of Kubernetes (installed via kops)
                      are limited to a fqdn length of 63 characters.
                    </div>
                  </div>
                </div>
              </div>

              {/* NETWORK ID */}
              <div class="form-group row" id="private-vpc-id" style="display: none">
                <label for="network-id-a" class="col-form-label">
                  Network ID
                </label>
                <div class="col">
                  <select
                    class="form-control form-select selectpicker vpc-dropdown"
                    data-live-search="true"
                    data-show-subtext="true"
                    data-size="10"
                    id="network-id-a"
                    name="network-id-a">
                    <option value="" disabled selected>
                      - Select a Network ID -
                    </option>
                  </select>
                </div>
              </div>

              {/* NETWORK DHCP OPTIONS */}
              <div class="form-group row" id="private-vpc-dhcp-options" style="display: none">
                <label class="col-form-label" />
                <div class="col">
                  <label class="checkbox-inline">
                    <input
                      type="checkbox"
                      value="create-dhcp"
                      id="create-dhcp"
                      name="create-dhcp"
                      onchange={() => notifyDhcpChange()}
                    />
                    <strong>Create new DHCP Option Set for my VPC</strong>
                  </label>
                </div>
              </div>
            </div>

            {/* CARD FOOTER */}
            <div class="card-footer">
              <input type="hidden" name="action" id="action" value="createJob" />
              <input type="hidden" name="jobType" id="jobType" value="create-hostedzone" />
              <input
                type="hidden"
                name="jobTypeDescription"
                id="jobTypeDescription"
                value="Create Route53 Hosted Zone"
              />
              <div class="row px-2">
                <div class="col">
                  <input
                    type="button"
                    name="resetFormBtn"
                    class="btn btn-secondary"
                    onclick={() => location.reload()}
                    value="Reset"
                  />
                </div>
                <div class="col" align="right">
                  <button id="submitButton" type="submit" class="btn btn-success" value="Order new Hosted Zone">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    Order new Hosted Zone
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function onPageReady() {
    $('#create-route53-hosted-zone-form').on('submit', function (e) {
      e.preventDefault();
      addSpinner();
      addButtonSpinner();
      checkHostedZone(submitHostedZoneOrder);
    });
  }

  return [page, onPageReady];
}
