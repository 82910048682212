import {
  addCopyButton,
  addLoadAccountDetailsButton,
  checkValueAgainstList,
  getWindowPosition,
  scrollToPosition,
  configureTableColumns,
  rowDetailsClickHandler,
  addLinkDXGWIdButton,
  showErrorFromApiOperation,
  displayError,
  displayErrorPopup,
  displaySuccessPopup,
} from './main';
import { accountIdPattern } from './accounts';
import { vpcTableColumns } from './networks';
import { baseApiAxios, loadSettings } from './api';
import { addSpinner, addTableSpinner, removeSpinners } from './sidebar';
import DXGWTabs from '../../jsx/components/forms/DXGWTabs';
import { initDataTable } from './datatable';

export const dxGwTableColumns = [
  { id: 'select_col' },
  { id: 'dx_gw_id_col', name: 'DX Gateway Id' },
  { id: 'dx_gw_name_col', name: 'DX Gateway Name' },
  { id: 'aws_account_id_col', name: 'Account ID' },
  { id: 'aws_region_col', name: 'Region' },
  { id: 'netbond_cidr_col', name: 'Netbond CIDR' },
  { id: 'associations_col', name: 'DX Gateway Associations' },
  { id: 'list_associations_col', name: 'List DX Gateway Associations' },
  { id: 'amazon_addresses_col', name: 'VIF amazon addresses' },
  { id: 'customer_addresses_col', name: 'VIF customer addresses' },
  { id: 'attachments_col', name: 'DX Gateway Attachments' },
  { id: 'virtual_interfaces_col', name: 'Virtual Interfaces' },
  { id: 'status_col', name: 'DX Gateway Status' },
  { id: 'status_update_col', name: 'Last Status Update (UTC)' },
  // { id: 'edit_col' },
];
const dxGwDbAttributes = [
  'id',
  'uuid',
  'dx_gw_id',
  'name',
  'aws_account_id',
  'aws_region',
  'netbond_cidr',
  'status',
  'status_update',
  'numbers',
  'dx_associations',
  'dx_virtual_interfaces',
];
const dxGwDropdownColumns = ['aws_region', 'network_type', 'aws_account_id', 'status'];
const dxGwSearchColumns = ['dx_gw_id', 'name', 'netbond_cidr', 'status_update'];
const dxGwSearchColumnsNumbers = ['numbers.dx_associations', 'numbers.dx_attachments', 'numbers.dx_virtual_interfaces'];

export function initDXGWTable(tableId) {
  configureTableColumns(tableId, dxGwTableColumns);
  let permissions = localStorage.permissions ? localStorage.permissions : false;

  initDataTable(
    tableId,
    'lCfrtpBi',
    [
      {
        text: 'Update all DX Gateways',
        action: function () {
          if (checkValueAgainstList('manage_networks', permissions)) {
            triggerAllDxGwUpdate();
          } else {
            displayErrorPopup(null, "You don't have permissions to update Direct Connect Gateways");
          }
        },
        className: 'updateAllDxGw',
        titleAttr: 'Trigger the update of all Direct Connect Gateways in the Shared Services Account',
      },
      {
        extend: 'excelHtml5',
        text: 'Export Excel',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as Excel file',
      },
      {
        extend: 'csvHtml5',
        text: 'Export CSV',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as CSV file',
      },
      {
        extend: 'copyHtml5',
        text: 'Copy',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Copy the visible columns into your clipboard',
      },
      {
        extend: 'resetTable',
        ajaxReload: false,
        titleAttr: 'Reset all filters in the table footer',
      },
    ],
    [
      {
        // Column 0
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: null,
        name: 'select_col',
        class: 'details-control',
      },
      {
        visible: true,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'dx_gw_id',
        name: 'dx_gw_id_col',
        title: 'DX Gateway Id',
        createdCell: function (td, _cellData) {
          addCopyButton(td);
          addLinkDXGWIdButton(td);
        },
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'name',
        name: 'dx_gw_name_col',
        title: 'DX Gateway Name',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'aws_account_id',
        name: 'aws_account_id_col',
        title: 'Account ID',
        createdCell: function (td, cellData) {
          addCopyButton(td);
          addLoadAccountDetailsButton(td);

          if (!accountIdPattern.test(cellData)) {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'aws_region',
        name: 'aws_region_col',
        title: 'Region',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          const pattern = /^[a-z]{2}-[a-z]{1,12}-\d$/;
          if (!pattern.test(cellData)) {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        type: 'ip-address',
        data: 'netbond_cidr',
        name: 'netbond_cidr_col',
        title: 'Netbond CIDR',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          const pattern = /^(\d{1,3}\.){3}\d{1,3}(\/(\d|[1-2]\d|3[0-2]))$/;
          if (!pattern.test(cellData)) {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.dx_associations',
        name: 'associations_col',
        title: 'DX Gateway Associations',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          if (cellData === 10) {
            $(td).addClass('portal-danger');
          } else if (cellData >= 7 && cellData <= 9) {
            $(td).addClass('portal-warning');
          }
        },
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'dx_associations',
        name: 'list_associations_col',
        title: 'List DX Gateway Associations',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'amazon_addresses',
        name: 'amazon_addresses_col',
        title: 'VIF amazon addresses',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'customer_addresses',
        name: 'customer_addresses_col',
        title: 'VIF customer addresses',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.dx_attachments',
        name: 'attachments_col',
        title: 'DX Gateway Attachments',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.dx_virtual_interfaces',
        name: 'virtual_interfaces_col',
        title: 'Virtual Interfaces',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'status',
        name: 'status_col',
        title: 'DX Gateway Status',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          if (cellData === 'available') {
            $(td).addClass('portal-success');
          } else {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'status_update',
        name: 'status_update_col',
        title: 'Last Status Update (UTC)',
        createdCell: addCopyButton,
      },
    ],
    function (row, data) {
      if (!data.aws_region) {
        $(row).addClass('portal-danger');
      }
    },
    {
      search: {
        regex: false,
      },
      order: [[2, 'asc']],
      initComplete: function () {
        updateDxGwTableFooter(tableId);
      },
      drawCallback: function () {
        $(window).trigger('resize');
        scrollToPosition();
        removeSpinners();
      },
      preDrawCallback: getWindowPosition,
    }
  );

  rowDetailsClickHandler({ tableId: tableId, rowDetailCallback: formatChildRowDX });
  loadDxGwData(tableId);
}

function loadDxGwData(tableId, exclusive_start_key) {
  $(() => addSpinner());
  $(() => addTableSpinner());

  const payload = {
    action: 'list-network-items',
    attributes: dxGwDbAttributes,
    item_type: 'DX_GW',
    exclusive_start_key: exclusive_start_key || undefined,
    table_data: { length: 500 },
  };

  baseApiAxios
    .getNetworkItems(payload)
    .then(result => appendDxGwData(result, tableId))
    .catch(err => displayError(`<strong>Error:</strong> ${err.message}`))
    .finally(() => {
      scrollToPosition();
      removeSpinners();
    });
}

export function getFreeDxGwNetbondCIDR() {
  let payload = {
    action: 'get-free-cidr',
    network_information: {
      network_size: 29,
      cidr_attribute: 'netbond_cidr',
    },
  };
  addSpinner();
  baseApiAxios
    .getNetworkItems(payload)
    .then(result => {
      displaySuccessPopup(result.message);
    })
    .catch(displayErrorPopup)
    .finally(() => removeSpinners());
}

function appendDxGwData(result, tableId) {
  const dt = $('#' + tableId).DataTable();

  const items = result.items;
  if (items) dt.rows.add(items).draw(false);
  if (items.length === 500) {
    const exclusive_start_key = {
      item_type: 'DX_GW',
      id: items[items.length - 1].id,
      uuid: items[items.length - 1].uuid,
      dx_gw_id: items[items.length - 1].dx_gw_id,
    };
    loadDxGwData(tableId, exclusive_start_key);
  } else {
    $(window).trigger('resize');
    updateDxGwSummary(tableId);
  }

  updateDxGwTableFooter(tableId);
}

function updateDxGwSummary(tableId) {
  let dt = $('#' + tableId).DataTable();
  let data = dt.rows().data().toArray();

  const statsOverview = document.getElementById('dx-gw-numbers');
  if (!statsOverview) return;
  statsOverview.innerHTML = '';

  const stats = {};
  // TODO: This filter is just a workaround in order to only connections from accounts which are shared services
  data.filter(item => item.aws_account_id === '833189485739').forEach(function (item) {
    if (!stats[item.aws_region]) {
      stats[item.aws_region] = {
        total: 10,
        used: item.numbers.dx_associations,
        free: 10 - item.numbers.dx_associations,
      };
    } else {
      stats[item.aws_region]['total'] += 10;
      stats[item.aws_region]['used'] += item.numbers.dx_associations;
      stats[item.aws_region]['free'] += 10 - item.numbers.dx_associations;
    }
  });

  loadSettings('network').then(object => {
    let statsContainer = document.createElement('div');
    statsContainer.setAttribute('class', 'statsContainer row row-cols-1 row-cols-sm-2 row-cols-xl-3');
    statsOverview.appendChild(statsContainer);

    let separator = document.createElement('hr');
    statsOverview.appendChild(separator);

    let settings = object.network.dx_gw_threshold;

    for (const region in stats) {
      let statsSettings = region in settings ? settings[region] : settings['default'];
      let container = document.createElement('div');
      container.setAttribute('class', 'col');
      statsContainer.appendChild(container);

      let statsCard = document.createElement('div');
      statsCard.setAttribute('class', 'statsCard');
      container.appendChild(statsCard);

      let statsCardBody = document.createElement('div');
      statsCardBody.setAttribute('class', 'statsCardBody');
      statsCard.appendChild(statsCardBody);

      // LEFT SIDE - HEADER
      let statsInfo = document.createElement('div');
      statsInfo.setAttribute('class', 'statsCardInfo');
      statsCardBody.appendChild(statsInfo);

      let statsInfoHeader = document.createElement('h5');
      statsInfoHeader.innerText = 'Region';
      statsInfo.appendChild(statsInfoHeader);

      let statsInfoRegion = document.createElement('h4');
      statsInfoRegion.innerText = region;
      statsInfo.appendChild(statsInfoRegion);

      // RIGHT SIDE - CONTENT
      let statsContent = document.createElement('div');
      statsContent.setAttribute('class', 'statsCardContent');
      statsCardBody.appendChild(statsContent);

      let statsContentHeader = document.createElement('h5');
      statsContentHeader.innerText = 'Associations';
      statsContent.appendChild(statsContentHeader);

      let totalValue = stats[region]['total'];
      let total = document.createElement('div');
      total.setAttribute('class', 'statsContentValue');
      total.innerHTML = totalValue + '<small> total</small>';
      statsContent.appendChild(total);

      let usedValue = stats[region]['used'];
      let used = document.createElement('div');
      used.setAttribute('class', 'statsContentValue');
      used.innerHTML = usedValue + '<small> used</small>';
      statsContent.appendChild(used);

      let freeValue = stats[region]['free'];
      let free = document.createElement('div');

      if (freeValue > statsSettings['warning']) {
        free.setAttribute('class', 'statsContentValue');
      } else if (freeValue > statsSettings['danger']) {
        free.setAttribute('class', 'statsContentValue warning');
        statsCardBody.setAttribute('class', 'statsCardBody warning');
      } else {
        free.setAttribute('class', 'statsContentValue danger');
        statsCardBody.setAttribute('class', 'statsCardBody danger');
      }
      free.innerHTML = freeValue + '<small> free</small>';
      statsContent.appendChild(free);
    }
  });
}

function updateDxGwTableFooter(tableId) {
  const dt = $('#' + tableId).dataTable();
  if (!dt.api().state()) return;
  const columnsState = dt.api().state().columns;

  dt.api()
    .columns()
    .every(function (col) {
      const that = this;
      const column = this;
      const title = column.header().innerHTML;
      const dataSource = column.dataSrc();
      const id = vpcTableColumns[column.index()].id;

      if (dxGwDropdownColumns.indexOf(dataSource) > -1) {
        const select = $('<select><option value=""></option></select>')
          .appendTo($(column.footer()).empty())
          .on('change', function () {
            const val = $.fn.dataTable.util.escapeRegex($(this).val());
            column.search(val ? '^' + val + '$' : '', true, false).draw();
          });

        column
          .data()
          .unique()
          .sort()
          .each(function (data) {
            let opt = document.createElement('option');
            opt.innerText = data;
            opt.value = data;
            select.append(opt);

            if (
              data &&
              columnsState[col].search &&
              columnsState[col].search.search &&
              data.match(columnsState[col].search.search)
            ) {
              opt.selected = true;
            }
          });
      }

      if (dxGwSearchColumns.indexOf(dataSource) > -1 || dxGwSearchColumns.indexOf(id) > -1) {
        $(column.footer()).html('<input type="text" placeholder="Search ' + title + '" />');

        if (columnsState[col].search && columnsState[col].search.search) {
          column.footer().lastChild.value = columnsState[col].search.search;
        }

        $('input', that.footer()).on('keyup change', function () {
          if (that.search() !== this.value) {
            that.search(this.value).draw();
          }
        });
      }

      if (dxGwSearchColumnsNumbers.indexOf(dataSource) > -1 || dxGwSearchColumnsNumbers.indexOf(id) > -1) {
        $(column.footer()).html('<input type="text" placeholder="Search ' + title + '" />');
        $('input', that.footer()).on('keyup change', function () {
          if (this.value) {
            if (that.search() !== this.value) {
              that.search('^' + this.value + '$', true, false).draw();
            }
          } else {
            that.search('').draw();
          }
        });
      }
    });
}

async function formatChildRowDX(row) {
  const data = row.data();
  try {
    const dxgw_result = await baseApiAxios.getNetworkItem(data.id, data.uuid, 'DX_GW');
    row.child(
      <div class="detailsContainer">
        <DXGWTabs data={dxgw_result} />
      </div>
    );
    row.child()[0].setAttribute('class', 'rowDetails');
  } catch (err) {
    showErrorFromApiOperation('Fetching Direct Connect Gateway data failed.')(err);
  }
}

function triggerAllDxGwUpdate() {
  const payload = { action: 'trigger-update-all-directconnect-gateways' };
  addSpinner();

  baseApiAxios
    .getNetworkItems(payload)
    .then(result => {
      displaySuccessPopup(result.message);
    })
    .catch(displayErrorPopup)
    .finally(() => removeSpinners());
}
